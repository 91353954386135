// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { capitalCase } from "change-case";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";
import useUsersList from "src/components/UsersAutoComplete/hooks/useUsersList";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import Iconify from "src/components/Iconify";
import * as XLSX from "xlsx";
import axios from "axios";
import { fDate } from "src/utils/formatNumber";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "src/utils/axios";
import useQueryParams from "src/hooks/useQueryParams";
import useAuth from "src/hooks/useAuth";
import Ternary from "src/components/ternary";

const DataFilter = ({
  setDataLoading,
  dataLoading,
  handleFilter,
  setFilter,
  filter,
  querys,
  setQuerys,
}) => {
  const { translate } = useLocales();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { start_date, end_date, user_id, payment_type } = filter;
  const usersList = useUsersList();
  const { deleteParam } = useQueryParams();
  const { user } = useAuth();
  const { user_type } = user || {};
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (querys) {
      const selected = options.find(({ name: uid }) => uid === querys);
      setSelectedOption(selected);
    }
  }, [querys]);

  const selected =
    user.holding_tank === 1
      ? holding.find(({ name: uid }) => uid === payment_type) || null
      : options.find(({ name: uid }) => uid === payment_type) || null;

  const onSubmit = () => {
    setDataLoading(true);
    handleFilter(1, {
      start_date,
      end_date,
      user_id,
      payment_type,
      querys,
    });
  };
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [loading, setLoading] = useState(false);

  const handleClickExcel = async (page = 1, filter = {}) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get("api/user/ewallet", {
        params: {
          page,
          excel: "excel",
          ...filter,
        },
      });

      if (status) {
        setLoading(false);
        const { data_unpaginated, total_amount } = data;
        const newArray = data_unpaginated?.map((item, i) => ({
          "No.": i + 1,
          "User Name": item.user.username,
          "From User": item.from_user.username,
          "Amount Type": capitalCase(item.payment_type),
          Amount: item.total_amount,
          Note: item.note,
          Date: fDate(item.created_at),
        }));

        const worksheet = XLSX.utils.json_to_sheet(newArray);

        // const dummyRow = { Amount: `Total : ${total_amount}` };

        // XLSX.utils.sheet_add_json(worksheet, [dummyRow], {
        //   skipHeader: true,
        //   origin: -1,
        // });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);
        XLSX.writeFile(workbook, "wallet Report.xlsx");
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LoadingButton
          loading={loading}
          onClick={() => handleClickExcel(1, filter)}
          variant="outlined"
          size="small"
          startIcon={<Iconify icon={"ri:file-excel-2-line"} />}
          sx={{ m: 1 }}
          name="excel"
        >
          Excel
        </LoadingButton>
      </Box>

      <Card sx={{ p: 3 }}>
        <Grid item xs={12} md={12}>
          {/* <FormProvider methods={methods} onSubmit={onSubmit}> */}
          <Box
            sx={{
              display: "grid",
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: {
                xs: "repeat(1,1fr)",
                sm: "repeat(5, 1fr)",
              },
            }}
          >
            <DatePicker
              onChange={(v) => (
                setFilter({
                  ...filter,
                  start_date: new Date(v).toLocaleDateString("en-GB"),
                }),
                setStartDate(v)
              )}
              inputFormat="dd/MM/yyyy"
              value={startDate}
              renderInput={(params) => (
                <TextField
                  name="start_date"
                  {...params}
                  error={null}
                  fullWidth
                  label="Start Date"
                  size="small"
                />
              )}
            />
            <DatePicker
              onChange={(v) => {
                return (
                  setFilter({
                    ...filter,
                    end_date: new Date(v).toLocaleDateString("en-GB"),
                  }),
                  setEndDate(v)
                );
              }}
              inputFormat="dd/MM/yyyy"
              value={endDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={null}
                  fullWidth
                  size="small"
                  label="End Date"
                />
              )}
            />
            {/* <Autocomplete
              onChange={(_, v) => {
                if (v) setFilter({ ...filter, user_id: v.user_id });
                else {
                  setFilter({ ...filter, user_id: null });
                }
              }}
              value={selected}
              options={usersList}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label="User Name"
                />
              )}
            /> */}
            {/* <Type /> */}

            <Ternary
              when={user_type === "investor" || user_type === "amc"}
              then={
                <>
                  {" "}
                  <Autocomplete
                    onChange={(_, v) => {
                      if (v) setFilter({ ...filter, payment_type: v.name });
                      else {
                        setFilter({ ...filter, payment_type: null });
                        setSelectedOption(null);
                      }
                    }}
                    value={selected}
                    options={user_type === "investor" ? investors : amc}
                    getOptionLabel={(option) => option.type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        label="Amount Type"
                      />
                    )}
                  />
                </>
              }
              otherwise={
                <>
                  <Autocomplete
                    onChange={(_, v) => {
                      if (v) setFilter({ ...filter, payment_type: v.name });
                      else {
                        setFilter({ ...filter, payment_type: null });
                        setSelectedOption(null);
                      }
                    }}
                    value={selectedOption ? selectedOption : selected}
                    options={user.holding_tank === 1 ? holding : options}
                    getOptionLabel={(option) => option.type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        label="Amount Type"
                      />
                    )}
                  />
                </>
              }
            />

            <LoadingButton
              loading={dataLoading}
              size="small"
              variant="contained"
              onClick={onSubmit}
            >
              Search
            </LoadingButton>

            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                deleteParam("packages");
                setFilter({
                  ...filter,
                  start_date: null,
                  end_date: null,
                  payment_type: null,
                });
                setStartDate(null);
                setEndDate(null);
                setSelectedOption(null);
                handleFilter(1);
              }}
              sx={{ width: "50%" }}
              endIcon={<Iconify icon="bx:reset" />}
            >
              Reset
            </Button>
          </Box>
          {/* </FormProvider> */}
        </Grid>
      </Card>
    </div>
  );
};
const options = [
  { name: "partner", type: "Partner Package" },
  { name: "sponsor", type: "Sponsor Package" },
  { name: "spartoken", type: "Spar Token Package" },
  { name: "restaurant_bonus", type: "Restaurant Bonus" },
  { name: "fund_transfer", type: "Fund Transfer" },

  {
    name: "payout",
    type: "Payout",
  },
];

const investors = [
  { name: "fund_transfer", type: "Fund Transfer" },
  { name: "investor_commission", type: "Investor Commission" },

  {
    name: "payout",
    type: "Payout",
  },
];

const amc = [
  { name: "fund_transfer", type: "Fund Transfer" },
  { name: "amc_commission", type: "Amc Commission" },

  {
    name: "payout",
    type: "Payout",
  },
];

const holding = [
  {
    name: "payout",
    type: "Payout",
  },
];

export default DataFilter;
