import { lazy } from "react";
import GuestGuard from "src/guards/GuestGuard";
import Loadable from "./Loadable";
import { Navigate, useParams } from "react-router";
const Login = Loadable(lazy(() => import("src/pages/auth/Login")));
const Register = Loadable(lazy(() => import("src/pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("src/pages/auth/resetPassword/index"))
);
const VerifyCode = Loadable(lazy(() => import("src/pages/auth/VerifyCode")));
const NewPassword = Loadable(
  lazy(() => import("src/pages/auth/NewPassword/index"))
);
const ChangedPassword = Loadable(
  lazy(() => import("src/pages/auth/ChangedPassword"))
);
const Success = Loadable(lazy(() => import("src/pages/auth/Success")));
const Failpage = Loadable(lazy(() => import("src/pages/auth/failure.js")));
const AccessToken = Loadable(lazy(() => import("src/pages/auth/accessToken")));
const WpLogin = Loadable(lazy(() => import("src/pages/auth/wpToken/wpToken")));
const SparissimoLogin = Loadable(
  lazy(() => import("src/pages/auth/SparissimoLogin"))
);

const MiddleWare = () => {
  const { uname } = useParams();
  return <Navigate to={`/${uname}`} />;
};
const auth = {
  path: "auth",
  element: <GuestGuard />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      children: [
        { index: true, element: <Register /> },
        {
          path: ":uname",
          element: <Register />,
        },
      ],
    },
    { path: "register/:uname", element: <MiddleWare /> },
    { path: "login-unprotected", element: <Login /> },
    { path: "register-unprotected", element: <Register /> },
    { path: "reset-password", element: <ResetPassword /> },
    { path: "verify", element: <VerifyCode /> },
    { path: "new-password/", element: <NewPassword /> },
    { path: "password-reset/success", element: <ChangedPassword /> },
    { path: "success", element: <Success /> },
    { path: "failure", element: <Failpage /> },
    { path: "access-token", element: <AccessToken /> },
    { path: "wp-login/:token", element: <WpLogin /> },
    { path: "sparissimo-api-login/:token", element: <SparissimoLogin /> },
  ],
};

export default auth;
