import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/Iconify";
import Logo from "src/components/Logo";
import { IconButtonAnimate } from "src/components/animate";
import Settings from "src/components/settings";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { RootStyle } from "src/layouts/shared";
import HoldingDashboardModal from "src/pages/userSide/dashboard/HoldingTankModal";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import Typography from "src/theme/overrides/Typography";
import axiosInstance from "src/utils/axios";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import Ternary from "src/components/ternary";
import SIcon from "src/images/s-world.webp";
import redirectionIcon from "src/images/export.png";
import fetchUser from "src/utils/fetchUser";
import Food from "src/images/food.png";

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const { isAdmin, user, getUser, externalLinks } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = async () => {
    const { status, data } = await axiosInstance.get(
      `api/user/update-holding-tank-status`
    );

    if (status === 200) {
      enqueueSnackbar(data.message);
      getUser();
      if (isChecked) {
        navigate(PATH_USER.user_dashboard);
      } else {
        localStorage.setItem("is_network_user", true);
        navigate(PATH_USER.onlineStore.partnerpackage);
      }
    }
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (user.sponsor_info != null) {
      handleDelete();
    }
  };

  const handleClose = () => {
    setIsChecked(false);
    setOpen(false);
  };

  const handleOpen = () => {
    const timer = setTimeout(() => {
      setOpen(true);
    });
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (isChecked && user?.sponsor_info === null) handleOpen();
  }, [isChecked]);

  useEffect(() => {
    if (user.holding_tank === 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [user]);

  const { translate } = useLocales();
  const german = localStorage.getItem("i18nextLng");
  const theme = useTheme();
  const handlePlatform = async () => {
    try {
      const { status, data } = await fetchUser("sso-login");
      if (status) {
        window.open(data.data, "_blank");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const { click_and_food, community_login, webapp_online, academy_login } =
    externalLinks || {};
  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}
          {isAdmin ? (
            <>
              {/* <Tooltip title="products">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.store.products}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:shopping-cart-outline" />
                </IconButtonAnimate>
              </Tooltip> */}

              <Tooltip title="Restaurant Summary">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.restaurants.restaurantsummary}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="ion:restaurant-sharp" />
                </IconButtonAnimate>
              </Tooltip>
              <Tooltip title="help center">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.communication.help_center}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="bx:support" />
                </IconButtonAnimate>
              </Tooltip>
              <Tooltip title="Package settings">
                <IconButtonAnimate
                  LinkComponent={Link}
                  to={PATH_DASHBOARD.settings.package_manager}
                  sx={{ mr: 1, color: "text.disabled" }}
                >
                  <Iconify icon="material-symbols:settings" />
                </IconButtonAnimate>
              </Tooltip>
            </>
          ) : null}

          {/* <Tooltip title="support">
            <IconButtonAnimate sx={{ mr: 1, color: "text.disabled" }}>
              <Iconify icon="bx:support" />
            </IconButtonAnimate>
          </Tooltip>
          <Tooltip title="ticket">
            <IconButtonAnimate sx={{ mr: 1, color: "text.disabled" }}>
              <Iconify icon="entypo:ticket" />
            </IconButtonAnimate>
          </Tooltip> */}

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {/* <Searchbar /> */}
            {!isAdmin && (
              <IconButtonAnimate
                variant="outlined"
                // href="https://www.sparissimo.world/"

                // target="_blank"
                onClick={handlePlatform}
                sx={{
                  width: isDesktop ? "250px" : "100%",
                  borderRadius: "10px",
                  borderRadius: "25px",
                  backgroundColor: theme.palette.primary.main,
                  padding: isDesktop ? "7px 9px 7px 9px" : "4px 6px 4px 6px",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  justifyContent: "space-between",
                }}
              >
                <img
                  src={SIcon}
                  style={{
                    width: isDesktop ? 30 : 20,
                    height: isDesktop ? 30 : 20,
                    marginRight: isDesktop ? "5px" : "",
                  }}
                />
                <span
                  style={{
                    fontSize: isDesktop ? "16px" : "11px",
                    fontWeight: isDesktop ? 900 : 600,
                    marginLeft: 1,
                    color: "#fff",
                  }}
                >
                  {translate("our_platforms")}
                </span>
                {isDesktop && (
                  <img
                    src={redirectionIcon}
                    style={{
                      width: 25,
                      height: 25,
                      marginLeft: "4px",
                      marginBottom: "5px",
                    }}
                  />
                )}
              </IconButtonAnimate>
            )}
            <Ternary
              when={
                user.user_type === "investor" ||
                user.user_type === "amc" ||
                isAdmin
              }
              then={""}
              otherwise={
                <>
                  <FormGroup>
                    <FormControlLabel
                      label={
                        isDesktop ? (
                          <span
                            style={{
                              color: "GrayText",
                              fontSize: isDesktop ? "14px" : "11px",
                              fontWeight: "bold",
                            }}
                          >
                            {german === "de"
                              ? translate("affilateGer")
                              : (isChecked
                                  ? translate("Turn On")
                                  : translate("Turn Off")) +
                                " " +
                                translate("affiliate")}
                          </span>
                        ) : null
                      }
                      control={
                        <Switch checked={isChecked} onChange={handleChange} />
                      }
                    />
                  </FormGroup>
                </>
              }
            />

            <LanguagePopover />
            <NotificationsPopover />
            {/* <ContactsPopover /> */}
            {/* <IconButtonAnimate onClick={() => handleOpenSettings()}>
              <Iconify icon="material-symbols:inbox-customize" />
            </IconButtonAnimate> */}
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>
      {!isAdmin ? (
        <HoldingDashboardModal handleClose={handleClose} open={open} />
      ) : (
        ""
      )}

      <Settings handleClose={handleCloseSettings} open={openSettings} />
    </>
  );
}
