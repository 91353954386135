import SvgIconStyle from "src/components/SvgIconStyle";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useDashboard from "src/pages/userSide/dashboard/useDashboard";
import { PATH_USER } from "src/routes/paths";

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  whatsapp: getIcon("ic_whatsapp"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  setting: getIcon("ic_settings"),
  online_store: getIcon("ic_store"),
  my_subscription: getIcon("ic_user_subscription"),
  business_builder: getIcon("ic_bb"),
  profile: getIcon("ic_profile"),
  help_center: getIcon("ic_helpcenter"),
  report: getIcon("ic_report"),
  tools: getIcon("ic_tools"),
  stake: getIcon("ic_spar-token"),
  partner: getIcon("ic_partner"),
  sponsor: getIcon("ic_sponsor"),
  spartoken: getIcon("ic_spartoken"),
  portal: getIcon("ic_portal"),
};

/**
 * This build the navbar fo r the user side, each index will be a section on the side panel.
 * The object takes in the following keys:
 *  - subheader : for title of the section
 *  - items     : for taking in nav items
 *      - title : string
 *      - path  : link
 *      - icon  : icon
 *      - children: items[]
 */

const getChildren = (current_package, translate) => {
  let routes = [
    {
      title: translate("Livewebinar"),
      path: "https://us06web.zoom.us/j/89225537657?pwd=U9W0wta4lSBS9pdi7WZeOT81vBsyeS.1",
    },
    {
      title: translate("Startertraining"),
      path: "https://webinar.sparissimopartner.com/11920/21357/startertraining.html?mode=L&v=4",
    },
    {
      title: translate("Producttraining"),
      path: "https://webinar.sparissimopartner.com/11918/21357/produkteschulung.html?mode=L&v=4v",
    },
  ];

  if (current_package === "Influencer" || current_package === "Premium") {
    routes = [
      ...routes,
      {
        title: translate("Leadercall"),
        path: "https://webinar.sparissimopartner.com/11919/21357/leadercall.html?mode=L&v=4",
      },
    ];
  }
  return routes;
};

const useNavConfig = () => {
  const { translate } = useLocales();
  const { data } = useDashboard();
  const { current_package } = data || {};
  const { user } = useAuth();
  return [
    {
      items: [
        {
          investor_portal: true,
          is_investor: false,
          is_holdingTank: true,
          title: translate("dashboard"),
          path: PATH_USER.user_dashboard,
          icon: ICONS.dashboard,
        },

        {
          investor_portal: true,
          is_investor: true,
          is_holdingTank: false,
          title:
            user.user_type === "investor" ? "Investor Portal" : "AMC Portal",
          path: PATH_USER.investor_portal.root,
          icon: ICONS.portal,
        },

        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("joinTelegram"),
          path: "https://t.me/+NJR3FZ2N2iw2MmI8",
          icon: ICONS.chat,
        },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("joinWhatsapp"),
          path: "https://chat.whatsapp.com/FRsmikrgXYT3NPAZpKvYCX",
          icon: ICONS.whatsapp,
        },
        // {
        //   title: translate("onlineStore"),
        //   path: PATH_USER.onlineStore.root,
        //   icon: ICONS.online_store,
        //   children: [
        //     // {
        //     //   title: translate("partnerPackages"),
        //     //   path: PATH_USER.onlineStore.partnerpackage,
        //     // },
        //     {
        //       title: translate("sponsorPackages"),
        //       path: PATH_USER.onlineStore.sponserpackage,
        //     },
        //     {
        //       title: translate("sparTokenPackages"),
        //       path: PATH_USER.onlineStore.spartoken,
        //     },
        //     // {
        //     //   title: "Product & subscription",
        //     //   path: PATH_USER.onlineStore.productSubscription.root,
        //     // },
        //     { title: translate("myOrders"), path: PATH_USER.my_orders.root },
        //   ],
        // },
        // {
        //   title: translate("mySubscriptions"),
        //   icon: ICONS.my_subscription,
        //   path: PATH_USER.subscriptions.root,
        // },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("partnerPackages"),
          icon: ICONS.partner,
          path: PATH_USER.onlineStore.partnerpackage,
        },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("sponsorPackage"),
          icon: ICONS.sponsor,
          path: PATH_USER.onlineStore.sponserpackage,
        },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("sparToken"),
          icon: ICONS.spartoken,
          path: PATH_USER.spartoken.root,
          children: [
            {
              investor_portal: true,
              is_investor: false,

              is_holdingTank: true,
              title: translate("packages"),
              path: PATH_USER.spartoken.packages,
            },
            {
              investor_portal: true,
              is_investor: false,

              is_holdingTank: true,
              title: translate("Stake"),
              path: PATH_USER.spartoken.stake,
            },
          ],
        },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: false,
          title: translate("genealogy"),
          icon: ICONS.kanban,
          path: PATH_USER.genealogy.root,
          children: [
            // { title:  translate("binary") , path: PATH_USER.genealogy.binary },
            {
              investor_portal: false,
              is_investor: false,

              is_holdingTank: true,
              title: translate("sponsor"),
              path: PATH_USER.genealogy.sponsor,
            },
            {
              investor_portal: false,
              is_investor: false,

              is_holdingTank: true,
              title: translate("tree"),
              path: PATH_USER.genealogy.tree,
            },
            // {
            //   title: translate("affiliateDashboard"),
            //   path: PATH_USER.genealogy.affiliate,
            // },
            // { title: translate("binaryLegSettings"), path: PATH_USER.genealogy.binaryLeg },
          ],
        },
        {
          investor_portal: true,
          is_investor: false,
          is_holdingTank: true,
          title: translate("financial"),
          path: PATH_USER.financial.root,
          icon: ICONS.ecommerce,
          children: [
            {
              investor_portal: true,
              is_investor: false,

              is_holdingTank: true,
              title: translate("myEWallet"),
              path: PATH_USER.financial.eWallet,
            },
            // { title: translate("depositWallet"), path: PATH_USER.financial.depositWallet },
            {
              investor_portal: false,
              is_investor: false,

              is_holdingTank: false,
              title: translate("fundTransfer"),
              path: PATH_USER.financial.fundsTransfer,
            },
            {
              investor_portal: true,
              is_investor: false,

              is_holdingTank: true,
              title: translate("requestPayout"),
              path: PATH_USER.financial.payout,
            },
          ],
        },
        // {
        //   title: translate("businessBuilder"),
        //   icon: ICONS.business_builder,
        //   path: PATH_USER.business_builder.root,
        //   children: [
        //     {
        //       title: translate("subscriptions"),
        //       path: PATH_USER.business_builder.subscriptions,
        //     },
        //     {
        //       title: translate("materials"),
        //       path: PATH_USER.business_builder.materials.root,
        //     },
        //   ],
        // },

        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: true,
          title: translate("notice.nav_menu"),
          icon: ICONS.calendar,
          path: PATH_USER.notice.root,
        },
        {
          investor_portal: true,
          is_investor: false,
          is_holdingTank: true,
          title: translate("myProfile"),
          icon: ICONS.profile,
          path: PATH_USER.profile.root,
        },

        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: true,
          title: translate("blogs"),
          icon: ICONS.blog,
          path: PATH_USER.blogs.root,
        },
        {
          investor_portal: true,
          is_investor: false,
          is_holdingTank: true,
          title: translate("helpCenter"),
          path: PATH_USER.helpCenter.root,
          icon: ICONS.help_center,
          children: [
            {
              investor_portal: false,
              is_holdingTank: true,
              is_investor: false,
              title: translate("faqs"),
              path: PATH_USER.helpCenter.faq.root,
            },
            // {
            //   title: translate("knowledgeBase"),
            //   path: PATH_USER.helpCenter.knowledgeBase.root,
            // },
            // {
            //   title: translate("emails"),
            //   path: PATH_USER.helpCenter.mails.all,
            // },
            {
              investor_portal: true,
              is_holdingTank: true,
              is_investor: false,
              title: translate("createTicket"),
              path: PATH_USER.helpCenter.createTicket.subCategory(),
            },
          ],
        },
        {
          investor_portal: false,
          is_investor: false,
          is_holdingTank: true,
          title: translate("Tools"),
          path: PATH_USER.tools.root,
          icon: ICONS.tools,
          children: [
            // {
            //   is_holdingTank: true,
            //   title: translate("Webinar"),
            //   children: getChildren(current_package, translate),
            // },
            {
              investor_portal: false,
              is_investor: false,
              is_holdingTank: true,
              title: translate("Documents"),
              path: PATH_USER.tools.documents,
            },
            {
              investor_portal: false,
              is_investor: false,
              is_holdingTank: true,
              title: translate("Videos"),
              path: PATH_USER.tools.videos,
            },
          ],
        },
        {
          investor_portal: true,
          is_investor: false,
          is_holdingTank: false,
          title: translate("incomeReport"),
          path: PATH_USER.incomeReport.root,
          icon: ICONS.report,
        },

        // {
        //   title: "Stake",
        //   path: PATH_USER.stake.root,
        //   icon: ICONS.stake,
        // },

        // {
        //   title: "Missed Points",
        //   path: PATH_USER.missedPoints.root,
        //   icon: ICONS.blog,
        // },
      ],
    },
  ];
};
export default useNavConfig;
