import { Box, Chip, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "src/components/Iconify";
import MyAvatar from "src/components/MyAvatar";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { USER_PURCHASE_STATUS } from "src/config";
import useIsUserInavtive from "src/hooks/use-is-user-inactive";
import Ternary from "src/components/ternary";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.3, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, isAdmin } = useAuth();
  const isUserActive = useIsUserInavtive();
  const { palette } = useTheme();

  const borderStyle = useMemo(() => {
    switch (isUserActive) {
      case USER_PURCHASE_STATUS.active: {
        return `3px solid ${palette.success.main}`;
      }
      case USER_PURCHASE_STATUS.inactive: {
        return `3px solid ${palette.error.main}`;
      }
      default: {
        return null;
      }
    }
  }, [isUserActive, palette]);

  const chipLabel = useMemo(() => {
    switch (isUserActive) {
      case USER_PURCHASE_STATUS.active: {
        return "active";
      }
      case USER_PURCHASE_STATUS.inactive: {
        return "inactive";
      }
      default: {
        return "inactive";
      }
    }
  }, [isUserActive]);
  const chipVariant = useMemo(() => {
    switch (isUserActive) {
      case USER_PURCHASE_STATUS.active: {
        return "success";
      }
      case USER_PURCHASE_STATUS.inactive: {
        return "error";
      }
      default: {
        return "default";
      }
    }
  }, [isUserActive]);

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={PATH_DASHBOARD.root}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <MyAvatar
          src={user.user_profile?.profile_image}
          sx={{
            border:
              user.user_type === "investor" || user.user_type === "amc"
                ? ""
                : borderStyle,
          }}
        />

        <Box
          sx={{
            ml: 2,
            width: "100%",
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography variant="subtitle2" noWrap>
              {user.user_profile?.first_name}
            </Typography>
            <Ternary
              when={user.user_type === "investor" || user.user_type === "amc"}
              then={""}
              otherwise={
                <>
                  {" "}
                  <Ternary
                    when={chipVariant !== "default"}
                    then={
                      <Chip
                        variant="outlined"
                        size="small"
                        label={chipLabel}
                        color={chipVariant}
                      />
                    }
                  />
                </>
              }
            />
          </Stack>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="body2"
              noWrap
              sx={{
                color: "text.secondary",
                mt:
                  user.user_profile?.first_name ||
                  isAdmin ||
                  user.user_type === "investor" ||
                  user.user_type === "amc"
                    ? ""
                    : -2,
              }}
            >
              {user.username}
            </Typography>
            {user.userKycStatus === "1" ? (
              <Box marginLeft={0.5}>
                <Iconify
                  icon={"mdi:tick-decagram"}
                  color="#28ad2c"
                  width={18}
                  height={18}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </RootStyle>
    </Link>
  );
}
